import React from "react"
import { PageProps } from "gatsby"
import { StoryblokEntry } from "../interfaces/Storyblok/StoryblokEntry.interface"
import DynamicTemplate from "../components/templates/DynamicTemplate/DynamicTemplate.component"
import { getParsedJSON } from "../utils/getParsedJSON/getParsedJSON.util"
import StoryblokEntryTemplateContext from "../interfaces/Storyblok/StoryblokEntryTemplateContext.interface"

const StoryblokEntryTemplate: React.FC<PageProps<StoryblokEntry>> = ({
  pageContext,
}) => {
  const getContext = (context: any): StoryblokEntryTemplateContext => {
    return {
      name: context.name,
      id: context.id,
      full_slug: context.full_slug,
      field_component: context.field_component,
      first_published_at: context.first_published_at,
      content: getParsedJSON(context.content),
    }
  }

  return <DynamicTemplate context={getContext(pageContext)} />
}

export default StoryblokEntryTemplate
